.congratulation-container .swal2-popup {
  width: 480px;
  height: 430px;
  font-family: 'Raleway', sans-serif;
}

.congratulation-container .congratulation-title-text {
  padding: 0;
  padding-top: 50px;
  font-weight: 500;
  font-size: 64px;
  line-height: 66px;
}

.congratulation-container .congratulation-sub-text {
  padding: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
}

.congratulation-container .congratulation-button {
  padding: 14px 60px;
  border-radius: 50px;
  background-color: #4fdfff;
  color: whitesmoke;
  font-weight: 700;
  font-size: 18px;
  margin: 10px 0 900px 0; /* '10px 0 40px or 60px 0' bottom, without '18px 0 71px 0' */
}

.congratulation-container .congratulation-button:hover {
  background-color: #106ba3;
  cursor: pointer;
}
