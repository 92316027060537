* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: inherit;
  border: 0;
  outline: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mainLoading {
  flex-grow: 1;
  display: grid;
  place-content: center;
  place-items: center;
}

.content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .content {
    justify-content: flex-start;
  }
}
